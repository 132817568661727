import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon } from 'bv-components';
import { t } from 'bv-i18n';
import classnames from 'classnames';
import { getLoading, getStatements } from 'ReferAFriend/selectors';
import StatementsSummary from './statements_summary';
import StatementsList from './statements_list';
import { loadStatements } from '../duck';

const Statements = ({ summary }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [statementsOpen, setStatementsOpen] = useState(false);
  const hasReferrals = summary?.numberOfFriends > 0;
  const { content, pageSize } = useSelector(getStatements);
  const loading = useSelector(getLoading);

  const showStatements = useCallback(() => setStatementsOpen(!statementsOpen), [statementsOpen]);

  const increasePageNumber = () => { setPage((prev) => prev + 1); };

  const handleStatementsOpenClick = () => {
    if (page === 0) {
      dispatch(loadStatements(page));
      increasePageNumber();
    }

    showStatements();
  };

  const viewMore = () => {
    dispatch(loadStatements(page));
    increasePageNumber();
  };

  return (
    <>
      <h4 className="bvs-header raf-title">{t('javascript.refer_a_friend.track_your_referrals')}</h4>
      <div className={classnames('bvs-card raf-page__section raf-page-statement', { 'has-referrals': hasReferrals, open: statementsOpen })}>
        { hasReferrals && (
        <Icon
          type="raf-faq-arrow"
          className="raf-tracking__arrow bvs-link"
          onClick={handleStatementsOpenClick}
        />
        )}
        <StatementsSummary summary={summary} />
        { hasReferrals && statementsOpen && (
        <StatementsList
          statements={content}
          numberOfFriends={summary.numberOfFriends}
          viewMore={viewMore}
          loading={loading}
          pageSize={pageSize}
        />
        )}
      </div>
    </>
  );
};

Statements.propTypes = {
  summary: PropTypes.instanceOf(Object).isRequired,
};

export default Statements;
