import PropTypes from 'prop-types';

const Title = ({ title }) => (
  <div className="bvs-header">{title}</div>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Title;
