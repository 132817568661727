import { useEffect } from 'react';
import { Spinner } from 'bv-components';
import RafVideo from 'ReferAFriend/components/raf_video';
import FaqList from 'ReferAFriend/components/faq_list';
import Title from 'ReferAFriend/components/title';
import Subtitle from 'ReferAFriend/components/subtitle';
import Steps from 'ReferAFriend/components/steps';
import Terms from 'ReferAFriend/components/terms';
import BannerImage from 'ReferAFriend/components/banner_image';
import InviteFriends from 'ReferAFriend/components/invite_friends';
import Statements from 'ReferAFriend/components/statements';
import { useSelector, useDispatch } from 'react-redux';
import reducer, { loadUserConfiguration } from '../duck';
import {
  getUserConfiguration,
} from '../selectors';

const { addReducers } = window.reduxState;

addReducers({
  raf: reducer,
});

const App = () => {
  const dispatch = useDispatch();
  const userConfiguration = useSelector(getUserConfiguration);
  const {
    bannerImage, title, subtitle, canRef, content,
    promotion, qrCode, link, shareIcons, videoUrl, faqs, termsAndConditions, summary,
  } = userConfiguration || {};

  useEffect(() => {
    dispatch(loadUserConfiguration());
  }, []);

  if (!userConfiguration) {
    return <Spinner />;
  }

  return (
    <div className="raf-page__wrapper">
      <div className="bvs-card raf-page__section offers-card">
        <BannerImage url={bannerImage} />
        <Title title={title} />
        <Subtitle subtitle={subtitle} />
        {canRef && (
          <>
            <hr />
            <Steps
              firstStep={content.firstStep}
              secondStep={content.secondStep}
              thirdStep={content.thirdStep}
              promotion={promotion}
            />
          </>
        )}
      </div>
      <InviteFriends
        qrCode={qrCode}
        link={link}
        shareIcons={shareIcons}
        canRef={canRef}
      />
      <Statements summary={summary} />
      {videoUrl && (
        <RafVideo
          videoUrl={videoUrl}
        />
      )}
      <FaqList faqs={faqs} />
      <Terms
        termsAndConditions={termsAndConditions}
        promotion={promotion}
      />
    </div>
  );
};

export default App;
