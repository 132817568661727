import PropTypes from 'prop-types';
import { Button, Spinner } from 'bv-components';
import { t } from 'bv-i18n';
import Statement from './statement';

const StatementsList = ({
  statements, numberOfFriends, viewMore, loading, pageSize,
}) => {
  const hasMore = numberOfFriends > statements?.length;

  const getButtonLabel = () => {
    const hiddenStatements = numberOfFriends - statements.length;
    const key = hiddenStatements > pageSize ? 'view_more_hidden_statements' : 'view_last_hidden_statements';

    return t(`javascript.refer_a_friend.${key}`, { hiddenStatements });
  };

  if (!statements) {
    return (<Spinner />);
  }

  return (
    <>
      {
        statements.map((statement) => (
          <Statement
            key={statement.registrationDate}
            {...statement}
          />
        ))
      }
      {hasMore && !loading && (
        <div className="raf-view-more">
          <Button
            className="raf-link__btn-view-more"
            label={getButtonLabel()}
            inverse
            secondary
            onClick={viewMore}
          />
        </div>
      )}
      {loading && (<Spinner />)}
    </>
  );
};

StatementsList.propTypes = {
  statements: PropTypes.arrayOf(Object).isRequired,
  numberOfFriends: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  viewMore: PropTypes.func.isRequired,
};

export default StatementsList;
