import {
  fetchStatements,
  fetchUserConfiguration,
} from './api';

const SET_USER_CONFIGURATION = 'referAFriend/SET_USER_CONFIGURATION';
const SET_STATEMENTS = 'referAFriend/SET_STATEMENTS';
const APPEND_STATEMENTS = 'referAFriend/APPEND_STATEMENTS';
const SET_LOADING = 'referAFriend/SET_LOADING';

const setUserConfiguration = (payload) => ({
  type: SET_USER_CONFIGURATION,
  payload,
});

const setStatements = (payload) => ({
  type: SET_STATEMENTS,
  payload,
});

const appendStatements = (payload) => ({
  type: APPEND_STATEMENTS,
  payload,
});

const setLoading = () => ({
  type: SET_LOADING,
});

export const loadStatements = (page) => (dispatch) => {
  dispatch(setLoading());
  fetchStatements(page)
    .then((response) => {
      if (page === 0) {
        dispatch(setStatements(response));
      } else {
        dispatch(appendStatements(response));
      }
    });
};

export const loadUserConfiguration = () => (dispatch) => (
  fetchUserConfiguration()
    .then((response) => dispatch(setUserConfiguration(response)))
);

export const initialState = {
  userConfiguration: null,
  statements: {
    content: [],
    pageSize: 0,
  },
  loading: true,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_STATEMENTS: {
      const { pageable: { pageSize } } = action.payload;
      return {
        ...state,
        statements: {
          ...action.payload,
          pageSize,
        },
        loading: false,
      };
    }
    case APPEND_STATEMENTS: {
      const { content } = action.payload;
      return {
        ...state,
        statements: {
          ...state.statements,
          content: [...state.statements.content, ...content],
        },
        loading: false,
      };
    }
    case SET_USER_CONFIGURATION:
      return {
        ...state,
        userConfiguration: {
          faq: [],
          promotion: {},
          ...action.payload,
        },
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
