import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { cmsAssetPath } from 'bv';
import classnames from 'classnames';

import withShareIdentifier from 'ReferAFriend/helpers/share_option_identifier';

const DEFAULT_EMAIL_ADDRESS = 'example@example.com';
const DEFAULT_SUBJECT = t('javascript.refer_a_friend.share_options.email_subject_line');

const ShareOptionsList = ({ shareIcons, refUrl }) => {
  const options = {
    facebook: {
      href: `https://www.facebook.com/sharer/sharer.php?u=${withShareIdentifier(refUrl, 'facebook')}`,
    },
    whatsapp: {
      href: `https://web.whatsapp.com/send?text=${withShareIdentifier(refUrl, 'whatsapp')}`,
      data: { 'data-action': 'share/whatsapp/share' },
    },
    email: {
      href: `mailto:${DEFAULT_EMAIL_ADDRESS}?subject=${DEFAULT_SUBJECT}&body=${withShareIdentifier(refUrl, 'email')}`,
    },
    qq: {
      href: `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${withShareIdentifier(refUrl, 'QQ')}`,
    },
    weibo: {
      href: `https://service.weibo.com/share/share.php?url=${withShareIdentifier(refUrl, 'weibo')}`,
    },
    twitter: {
      href: `https://twitter.com/intent/tweet?text=${withShareIdentifier(refUrl, 'twitter')}`,
    },
  };

  return (
    <div className="raf-link__content">
      <div className="flex">
        {shareIcons.map(({ name, image }) => (
          <a
            key={name}
            className={classnames('raf-share-option', { 'raf-social-placeholder': !image })}
            href={options[name].href}
            target="_blank"
            rel="noopener noreferrer"
          >
            { image && <img src={cmsAssetPath(image)} alt={name} /> }
          </a>
        ))}
      </div>
    </div>
  );
};

ShareOptionsList.propTypes = {
  refUrl: PropTypes.string,
  shareIcons: PropTypes.instanceOf(Array),
};

ShareOptionsList.defaultProps = {
  refUrl: '',
  shareIcons: [],
};

export default ShareOptionsList;
