import PropTypes from 'prop-types';
import { Icon } from 'bv-components';
import { t } from 'bv-i18n';

const QrCode = ({ code }) => (
  <div className="raf-qr-code">
    <a
      download={`${t('javascript.refer_a_friend.download_qr_code_filename')}.png`}
      href={code}
      data-react
    >
      <Icon
        className="raf-download-qr-code bvs-link"
      />
    </a>
    <img src={code} alt="" />
  </div>
);

QrCode.propTypes = {
  code: PropTypes.string,
};

QrCode.defaultProps = {
  code: '',
};

export default QrCode;
