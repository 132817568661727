import PropTypes from 'prop-types';

const Subtitle = ({ subtitle }) => (
  <p>{subtitle}</p>
);

Subtitle.propTypes = {
  subtitle: PropTypes.string.isRequired,
};

export default Subtitle;
