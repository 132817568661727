import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import ReferralLink from 'ReferAFriend/components/referral_link';
import QrCode from 'ReferAFriend/components/qr_code';
import ShareOptionsList from 'ReferAFriend/components/share_options_list';

const InviteFriends = ({
  canRef, link, qrCode, shareIcons,
}) => (
  <>
    <h4 className="bvs-header raf-title">{t('javascript.refer_a_friend.invite_friends')}</h4>
    <div className=" bvs-card raf-page__section raf-page-share">
      { canRef ? (
        <>
          <p>{t('javascript.refer_a_friend.share_your_link')}</p>
          <hr />
          <ReferralLink link={link} />
          <hr />
          {qrCode && <QrCode code={qrCode} />}
          <hr />
          <p>{t('javascript.refer_a_friend.share_options_text')}</p>
          <ShareOptionsList
            shareIcons={shareIcons}
            refUrl={link}
          />
        </>
      ) : <p>{t('javascript.refer_a_friend.cant_invite_friends')}</p> }
    </div>
  </>
);

InviteFriends.propTypes = {
  canRef: PropTypes.bool.isRequired,
  link: PropTypes.string.isRequired,
  qrCode: PropTypes.string.isRequired,
  shareIcons: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default InviteFriends;
