import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { toCurrency } from 'bv-helpers/number';
import { Step } from 'SharedComponents/step';

const Steps = ({
  firstStep,
  secondStep,
  thirdStep,
  promotion: {
    awardAmount,
    parentAwardAmount,
    steps: childSteps,
  },
}) => {
  const steps = [
    { text: firstStep },
    { text: secondStep, extraInfo: (childSteps || []).map((s) => s.text).join('\n') },
    {
      text: thirdStep,
      extraInfo: awardAmount && parentAwardAmount ? (
        t('javascript.refer_a_friend.reward_step_subtitle', {
          parent_award_amount: toCurrency(parentAwardAmount),
          award_amount: toCurrency(awardAmount),
        })
      ) : null,
      type: 'reward',
    },
  ];

  return (
    <ul className="offer-steps">
      {steps.map((step, i) => <Step key={step.text} {...step} index={i + 1} />)}
    </ul>
  );
};

Steps.propTypes = {
  firstStep: PropTypes.string.isRequired,
  secondStep: PropTypes.string.isRequired,
  thirdStep: PropTypes.string.isRequired,
  promotion: PropTypes.instanceOf(Object).isRequired,
};

export default Steps;
