import PropTypes from 'prop-types';
import { toCurrency } from 'bv-helpers/number';
import { t, locale } from 'bv-i18n';
import StatementsEntry from './statements_entry';

// Add `T` between date + time for old IOS clients, see https://betvictor.atlassian.net/browse/NOC-52466
const formatDate = (date) => new Date(date.replace(/(^\d{4}-\d{2}-\d{2}) /, '$1T')).toLocaleDateString(locale(), {
  month: 'short',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  year: new Date(date).getFullYear() !== new Date().getFullYear() ? 'numeric' : undefined,
});

const Statement = ({
  promoReferralCode,
  parentAwardAmount,
  registrationDate,
  status,
}) => {
  const entries = [
    {
      label: t('javascript.refer_a_friend.transaction_id'),
      value: promoReferralCode,
      className: 'transaction-id',
    },
    {
      label: t('javascript.refer_a_friend.status'),
      value: t(`javascript.refer_a_friend.${status.toLocaleLowerCase()}`),
      className: status.toLocaleLowerCase(),
    },
    {
      label: t('javascript.refer_a_friend.registration_time'),
      value: formatDate(registrationDate),
    },
    {
      label: t('javascript.refer_a_friend.referral_reward'),
      value: toCurrency(parentAwardAmount),
    },
  ];

  return (
    <div className="raf-page__statement">
      { entries.map((entry) => <StatementsEntry key={entry.label} {...entry} />) }
    </div>
  );
};

Statement.propTypes = {
  promoReferralCode: PropTypes.string.isRequired,
  parentAwardAmount: PropTypes.string.isRequired,
  registrationDate: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default Statement;
