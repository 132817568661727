import PropTypes from 'prop-types';
import { cmsAssetPath } from 'bv';

const BannerImage = ({ url }) => (
  <img
    alt="Banner"
    className="raf-banner"
    src={cmsAssetPath(url)}
  />
);

BannerImage.propTypes = {
  url: PropTypes.string.isRequired,
};

export default BannerImage;
